import "./sobre.css";
import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Navbar from '../Components/Navbar/navbar';
import { toast } from "react-toastify";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { auth, db } from '../../service/firebaseConnection';
import { v4 as uuidv4 } from 'uuid';
import { collection, getDocs, addDoc } from "firebase/firestore";
import { onAuthStateChanged } from 'firebase/auth';

const Sobre = () => {
  const [data, setData] = useState([]);
  console.log(data, "data");
  const [isEditing, setIsEditing] = useState(false);
  const [paragraph, setParagraph] = useState("");
  const [selectedFiles, setSelectedFiles] = useState();
  const [id, setId] = useState("");
  const [img, setImg] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(false);
  const endpointBucket = process.env.REACT_APP_BUCKET_ENDPOINT;
  const accessKeyId = process.env.REACT_APP_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_SECRET_ACCESS;
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    setParagraph(event.target.value);
  };

  const s3Client = new S3Client({
    region: "us-east-1",
    endpoint: endpointBucket,
    credentials: {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      selectedFiles.length === 0 ||
      paragraph === ""
    ) {
      toast.error("Por favor, preencha todos os campos!");
      return;
    }

    try {
      const uploadPromises = Array.from(selectedFiles).map(async (file) => {
        const uploadParams = {
          Bucket: 'rodrigo-carpenedo-imoveis',
          Key: file.name,
          Body: file,
          ContentType: file.type,
        };

        const command = new PutObjectCommand(uploadParams);
        const response = await s3Client.send(command);
        console.log(`Arquivo ${file.name} enviado com sucesso:`, response);

        return `https://pub-8317e9c8f366474496cee204e4f982fc.r2.dev/rodrigo-carpenedo-imoveis/${uploadParams.Key}`;
      });

      const generatedId = uuidv4(); // ou use sua lógica de ID
      const imgUrls = await Promise.all(uploadPromises);
      toast.success("Imagens enviadas com sucesso!");

      const docRef = await addDoc(collection(db, "about"), {
        description: paragraph,
        img: imgUrls,
      });
      console.log("Documento adicionado com ID: ", generatedId);
      setId(generatedId);
      toast("Propriedade adicionada com sucesso!");
    } catch (error) {
      console.error("Erro ao adicionar propriedade: ", error);
      toast.error("Erro ao adicionar propriedade: " + error.message);
    }
  };

  useEffect(() => {
    const getData = async () => {
      await getDocs(collection(db, 'about'))
        .then((cliente) => {
          let clienteData = cliente.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(clienteData);
          toast.success("Dados carregados com sucesso!");
        })
        .catch((err) => {
          toast.warning("Ops, houve um problema ao carregar as informações");
          console.log(err);
        });
    };
    getData();
  }, [db]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setIsAuthenticated(true);
        setUser(currentUser);
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [data]);

  return (
    <div className="body">
      <Navbar />
      <Container maxWidth="md">
        <Typography variant="h4" component="h1" gutterBottom>
          Sobre Rodrigo Carpenedo Imóveis
        </Typography>

        <Box mt={4}>
          {
            data.map((item) => {
              const imgs = item.img;
              return (
                <img width="350px" alt="Logo" src={imgs[0]} />
              )
            })
          }
          {
            isAuthenticated ? (<input type="file" onChange={(e) => setSelectedFiles(e.target.files)} />) : (<></>)
          }
          {/* Se estiver no modo de edição, exibe um TextField; caso contrário, exibe o parágrafo */}
          {isAuthenticated ? (
            <TextField
              fullWidth
              multiline
              rows={4}
              value={data.map((item) => item.description)}
              onChange={handleChange}
              variant="outlined"
            />
          ) : (
            <Typography variant="body1" component="p" gutterBottom>
              {data.map((item) => item.description)}
            </Typography>
          )}

          {/* Alterna os botões de "Editar" e "Salvar" */}
          <Box mt={2}>
            {
              isAuthenticated ? (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                Salvar
              </Button>
              ) : (<></>)
            }
              
          </Box>
        </Box>
      </Container>
      <FloatingWhatsApp chatMessage="Olá, como podemos ajudar?" darkMode allowEsc accountName="Rodrigo Carpenedo imóveis" phoneNumber="65996875543" />
      <div className="footer">
      </div>
    </div>
  );
};

export default Sobre;
