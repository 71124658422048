import "./home.css";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Logo from '../../assets/img/logo.png';

const Home = () => {
  return (
    <div className="body">
      <div className="header">
        <div className="header-top">
          <div className="container">
            <ul className="header-top-list">
            </ul>
          </div>
          <div className="container-banner">              
            <div className="title-banner">
              <img className="logo-hom" src={Logo} />
              <div className="button-show">
                <Button
                  onClick={() => (window.location = "/Imoveis")}
                  variant="contained"
                  color="info"

                >
                  Ver imóveis
                </Button>
              </div>
            </div>
          </div>
        </div>
        <FloatingWhatsApp chatMessage="Olá, como podemos ajudar?" darkMode allowEsc accountName="Rodrigo Carpenedo imóveis" phoneNumber="65996875543" />
      </div>
      <footer></footer>
    </div>
  );
};

export default Home;
