import "./avaliacao.css";
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import Logo from '../../assets/img/logo.png';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Navbar from '../Components/Navbar/navbar';

const Sobre = () => {

  const [nome, setNome] = useState("");
  const [tipo, setTipo] = useState("");

  const propertiesTypes = [
    { id: 1, nome: "Casa" },
    { id: 2, nome: "Apartamento" },
    { id: 3, nome: "Barracão" },
    { id: 4, nome: "Terreno" },
    { id: 5, nome: "Sala comercial" },
    { id: 6, nome: "Sitio" },
    { id: 7, nome: "Fazenda" },
    { id: 8, nome: "Kitnet" },
  ];

  return (
    <div className="body">
      <Navbar />
      <Container maxWidth="md">
        <TextField
          id="nome"
          label="Nome"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setNome(e.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel className="label-description">Tipo de Imóvel</InputLabel>
          <Select
            fullWidth={true}
            onChange={(e) => setTipo(e.target.value)}
          >
            {propertiesTypes.map((item) => (
              <MenuItem key={item.id} value={item.nome}>{item.nome}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="container-buttons-modal" style={{ gridColumn: 'span 2', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
          <Button
            style={{ margin: '0 15px' }}
            variant="contained"
            color="success"
            target="_blank"
            href={`https://api.whatsapp.com/send?phone=5565999676622&text=Olá, me chamo ${nome} gostaria de solicitar uma avaliação do meu imóvel do tipo ${tipo}`}
          >
            Entrar em contato
          </Button>
        </div>
      </Container>
      <FloatingWhatsApp chatMessage="Olá, como podemos ajudar?" darkMode allowEsc accountName="Rodrigo Carpenedo imóveis" phoneNumber="65996875543" />
      <div className="footer">
      </div>
    </div>
  );
};

export default Sobre;
